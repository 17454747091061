import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { OpenStreetMapProvider } from "leaflet-geosearch";
import { SearchResult } from "leaflet-geosearch/providers/provider";
import { Point } from "../components/content/Map";


interface State {
    searchText: string;
    searchResult: Point;
}

const initialState: State = {
    searchText: "",
    searchResult: null,
};

export const searchRequest = createAsyncThunk(
    "locationSearch/searchRequest",
    async (searchText: string) => {
        const provider = new OpenStreetMapProvider({
            params: {
                "accept-language": "de", 
                "countrycodes": "de", 
            },
        });
        return provider.search({ query: searchText });
    }
);

const slice = createSlice({
    name: "locationSearch",
    initialState,
    reducers: {
        submitSearch: (state, action: PayloadAction<string>) => {
            const { payload } = action;
            state.searchText = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(searchRequest.fulfilled, (state, action) => {
            const results: SearchResult[] = action.payload;
            if (results.length == 0) {
                state.searchResult = initialState.searchResult;
                return;
            }
            // We return the first match, assuming its the closest match to what the user is 
            // searching for.
            state.searchResult = {
                name: results[0].label,
                longitude: results[0].x,
                latitude: results[0].y,
            };
        });
    }
});

export default slice.reducer;
export const { submitSearch } = slice.actions;

